import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function BlogDetails() {
  const Site_Url = "https://namechangeconsultants.co.in/";
  const BASE_URL = Site_Url + "admin/API/";

  //Testimonial API
  const [clientdata, setclientdata] = useState([]);
  useEffect(() => {
    const Allclient = async () => {
      const response = await fetch(`${BASE_URL}clientAPI.php`);
      const jsonData = await response.json();
      setclientdata(jsonData);
    };
    Allclient();
  }, [BASE_URL]);

  let { slug } = useParams();
  //Blog Details
  const [Blogdetails, setBlogdetailsdetails] = useState([]);

  useEffect(() => {
    const BlogdetailsData = async () => {
      const response = await fetch(
        `${BASE_URL}blogDetailsAPI.php?slugname=${slug}`
      );
      const jsonData = await response.json();
      setBlogdetailsdetails(jsonData);
    };
    BlogdetailsData();
  });

  const [Catlang, setCatlang] = useState("");
  useEffect(() => {
    const languagecategory = Blogdetails.lang;
    setCatlang(languagecategory);
  }, [Blogdetails.lang]);

  //Language Vise Blog API
  const [blog, setBlogsdata] = useState([]);
  useEffect(() => {
    const AllBlogs = async () => {
      const response = await fetch(
        `${BASE_URL}catblogAPI.php?langcat=${Catlang}`
      );
      const jsonData = await response.json();

      setBlogsdata(jsonData);
    };
    AllBlogs();
  }, [Catlang, BASE_URL]);

  //website Setting API
  const [webSetting, setwebSetting] = useState([]);
  useEffect(() => {
    const webSettingData = async () => {
      const response = await fetch(`${BASE_URL}website_settingAPI.php`);
      const jsonData = await response.json();
      setwebSetting(jsonData);
    };
    webSettingData();
  }, [BASE_URL]);

  const showBanner = () => {
    let AddData = "";
    if (Blogdetails.uploadType === "0") {
      AddData = (
        <div className="blog_iframe">
          <iframe
            width="100%"
            height="420"
            src={`https://www.youtube.com/embed/${Blogdetails.bannerVideo}`}
            title="Name Change Service in India | Name Change Agent in India"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen=""
          ></iframe>
        </div>
      );
    } else if (Blogdetails.uploadType === "1") {
      AddData = (
        <img
          src={Blogdetails.bannerImage}
          alt={Blogdetails.image_alt}
          title={Blogdetails.image_title}
          className="bannerimg"
        />
      );
    } else {
      AddData = (
        <div className="banner_desc">
          <div className="BannerDsceBox">
            <h2
              dangerouslySetInnerHTML={{
                __html: Blogdetails.bannerDesc,
              }}
            ></h2>
          </div>
        </div>
      );
    }
    return AddData;
  };

  const options1 = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: true,
    loop: true,
    autoplay: true,
    navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>{Blogdetails.meta_title}</title>
        <meta name="description" content={Blogdetails.meta_description} />
        <meta name="keywords" content={Blogdetails.meta_keyword} />
        <link rel="canonical" href={window.location.href} />

        {Blogdetails.custom_script && (
          <script type="application/ld+json">{Blogdetails.custom_script}</script>
        )}

        {Blogdetails.custom_script2 && (
          <script type="application/ld+json">{Blogdetails.custom_script2}</script>
        )}
        
      </Helmet>

      <section className="blog_detail_sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="blog_detail">
                <div className="row mt-3">
                  <div className="col-lg-8 col-md-12 mb-3">
                    <div className="blog_detail">{showBanner()}</div>
                  </div>

                  <div className="col-lg-4 col-md-12">
                    <div className="form_id">
                      <iframe
                        width="100%"
                        height="420"
                        title="Father Name Change"
                        src="https://manage.yourdoorstep.co/forms/ticket"
                        frameBorder="0"
                        allowFullScreen
                        style={{ border: "2px solid red" }}
                      ></iframe>
                    </div>
                  </div>
                </div>

                <h3>{Blogdetails.title}</h3>
                <p>{Blogdetails.tagline}</p>

                <div className="row mb-5 align-items-center">
                  <div className="col-md-6">
                    <div className="blog_date">
                      <span>
                        <NavLink to="/">
                          <i className="fa fa-clock-o" aria-hidden="true"></i>
                          <time>{Blogdetails.date} </time>
                        </NavLink>
                      </span>

                      <span>
                        <NavLink to="/">
                          <i
                            className="fa fa-commenting-o"
                            aria-hidden="true"
                          ></i>
                          <time>{Blogdetails.category}</time>
                        </NavLink>
                      </span>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="blog_social_icon">
                      <span>
                        <NavLink
                          className="fb_share"
                          onClick={() => {
                            window.open(
                              "https://www.facebook.com/sharer/",
                              "Share This Post",
                              "width=640,height=450"
                            );
                          }}
                          title="facebook"
                        >
                          <i className="fa fa-facebook" aria-hidden="true"></i>
                        </NavLink>
                      </span>
                      <span>
                        <NavLink
                          className="fb_twitter"
                          onClick={() => {
                            window.open(
                              "https://twitter.com/",
                              "Share This Post",
                              "width=640,height=450"
                            );
                          }}
                          title="Twitter"
                        >
                          <i className="fa fa-twitter" aria-hidden="true"></i>
                        </NavLink>
                      </span>
                      <span>
                        <NavLink
                          to={
                            Catlang === "en"
                              ? `https://api.whatsapp.com/send?text=${slug} - ${webSetting.baseURl}${slug}`
                              : `https://api.whatsapp.com/send?text=${slug} - ${webSetting.baseURl}${Catlang}/${slug}`
                          }
                          className="fb_whatsapp"
                          title="Whatsapp"
                          rel="nofollow noopener"
                          target="_blank"
                        >
                          <i className="fa fa-whatsapp" aria-hidden="true"></i>
                        </NavLink>
                      </span>
                      <span>
                        <NavLink
                          className="fb_linkedin"
                          onClick={() => {
                            window.open(
                              "http://www.linkedin.com/shareArticle/",
                              "Share This Post",
                              "width=640,height=450"
                            );
                          }}
                          title="linkedin"
                        >
                          <i className="fa fa-linkedin" aria-hidden="true"></i>
                        </NavLink>
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  dangerouslySetInnerHTML={{
                    __html: Blogdetails.description,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*Start How Work */}
      <section className="work_sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="work_tx">
                <h2>How It Works </h2>
                <p>
                  {" "}
                  We provide best name change consultant which provide reliable
                  solution for your name change in your legal document
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-6 mt-2">
              <div className="work_img">
                <i className="fa fa-hand-o-up" aria-hidden="true"></i>
                <h6>Select Name Change Consultant</h6>
              </div>
            </div>

            <div className="col-lg-2 col-md-4 col-sm-6 mt-2">
              <div className="work_img">
                <i className="fa fa-envelope-o" aria-hidden="true"></i>
                <h6>Send Inquiry</h6>
              </div>
            </div>

            <div className="col-lg-2 col-md-4 col-sm-6 mt-2">
              <div className="work_img">
                <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                <h6>Ask Question About Name Change</h6>
              </div>
            </div>

            <div className="col-lg-2 col-md-4 col-sm-6 mt-2">
              <div className="work_img">
                <i className="fa fa-file-text" aria-hidden="true"></i>
                <h6>Send Document For Name correction</h6>
              </div>
            </div>

            <div className="col-lg-2 col-md-4 col-sm-6 mt-2">
              <div className="work_img">
                <i className="fa fa-credit-card" aria-hidden="true"></i>
                <h6>Pay Online</h6>
              </div>
            </div>

            <div className="col-lg-2 col-md-4 col-sm-6 mt-2">
              <div className="work_img">
                <i className="fa fa-hourglass-start" aria-hidden="true"></i>
                <h6>Work Start</h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End How Work */}

      {Blogdetails.description2 && (
        <div className="container  mt-3  mb-3">
          <div className="row">
            <div className="col-md-12 col-12">
              <div
                dangerouslySetInnerHTML={{
                  __html: Blogdetails.description2,
                }}
              ></div>
            </div>
          </div>
        </div>
      )}

      {/* Testimonials Start */}
      <section className="testimonial_sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="about_tx text-center">
                <h4>Testimonials </h4>
                <h2> We love our clients</h2>
              </div>

              <OwlCarousel className="owl-theme" {...options1}>
                {clientdata.map((value, index) => (
                  <div key={index} className="item client_review">
                    <img src={value.image} alt="" title="" />
                    <h5>{value.name}</h5>
                    <p className="text-center">{value.designation}</p>
                    <p>{value.description}</p>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
      {/* Testimonials Start */}

      {Blogdetails.description3 && (
        <div className="container mt-3  mb-3">
          <div className="row">
            <div className="col-md-12 col-12">
              <div
                dangerouslySetInnerHTML={{
                  __html: Blogdetails.description3,
                }}
              ></div>
            </div>
          </div>
        </div>
      )}

      {/* Blog Start */}
      <section className="blog_sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="service_title blog_title">
                <h2>Blogs</h2>
              </div>
            </div>
          </div>

          <div className="row">
            {blog.map((value, index) => (
              <div className="col-lg-4 col-md-6 col-sm-6 mb-3" key={index}>
                <div className="blog_bx">
                  <NavLink
                    to={
                      value.lang === "en"
                        ? `/${value.slug}`
                        : `/${value.lang}/${value.slug}`
                    }
                  >
                    <img
                      src={value.sort_img}
                      alt={value.image_alt}
                      title={value.image_title}
                    />
                  </NavLink>
                  <div className="blog_tx">
                    <span>
                      <i className="fa fa-calendar" aria-hidden="true"></i>{" "}
                      {value.date}
                    </span>
                    <h5>
                      <NavLink
                        to={
                          value.lang === "en"
                            ? `/${value.slug}`
                            : `/${value.lang}/${value.slug}`
                        }
                      >
                        {value.title}
                      </NavLink>
                    </h5>
                    <p className="blog_tx_tg">{value.tagline}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* Blog End */}
    </>
  );
}
